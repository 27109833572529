<template>
  <DetailLayout
    full-height
    full-width
    title="System Admin"
    :nav-items="navItems"
  >
    <template v-if="showImportButton" #aside-prefix>
      <AtroButton :pending text="Import Baserow" @click="onImport" />
    </template>
    <template #aside-postfix>
      <AtroContent
        class="relative flex-1"
        justify="end"
        direction="col"
        wrap="nowrap"
      >
        <UserMenu />
      </AtroContent>
    </template>
    <slot />
  </DetailLayout>
</template>

<script setup lang="ts">
import DetailLayout from './detail.vue'

const { pending, wrapWithPending } = usePendingWrap()
const runtimeConfig = useRuntimeConfig()
const toast = useToast()

const navItems = [
  {
    label: 'Orgs',
    path: '/admin/orgs',
  },
  {
    label: 'Icons',
    path: '/admin/icons',
  },
  {
    label: 'Services',
    path: '/admin/services',
  },
]
const showImportButton = computed(
  () =>
    runtimeConfig.public.VERCEL_ENV === 'preview' ||
    import.meta.env.VITE_ENVIRONMENT === 'development',
)

const onImport = wrapWithPending(async () => {
  toast.success('Import started! Imports take between 20 - 30 seconds')
  try {
    await $fetch(getApiPath('importContent'), {
      retry: 0,
    })
  } catch {}
})
</script>
